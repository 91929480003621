import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import "./index.css"
import menus from "pages/Authen/menus"
import { RESOURCE_KEY } from "lib/Const"
import { useTranslation } from "react-i18next"

function convertMenus(menus, permission) {
  let __menus = []
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const IconX = () => <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
    let __children = []
    const newChildren = menu.sub_menu
    if (newChildren?.length > 0) {
      for (let j = 0; j < newChildren?.length; j++) {
        const IconY = () => <span dangerouslySetInnerHTML={{ __html: newChildren[j].icon }} />
        const item = {
          url: menu.url + newChildren[j].url,
          title: newChildren[j].title,
          icon: <Icon component={IconY} />
        }

        if (permission[newChildren[j].code] || newChildren[j].code === RESOURCE_KEY.ROOT_BYPASS)
          __children.push(item)
      }
    }
    if (permission[menu.code] || menu.code === RESOURCE_KEY.ROOT_BYPASS) {
      __menus.push({
        url: menu.url,
        title: menu.title,
        icon: <Icon component={IconX} />,
        children: __children
      })
    }
  }

  return __menus
}

function MyLayout({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user, permission } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)
  const { t } = useTranslation()

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }
  return (
    <div className="flex min-h-screen">
      <Navbar items={convertMenus(menus, permission)} isNav={isNav} />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-between custom_white border-b py-2 pr-6 pl-4 h-14 w-full sticky top-0 z-20">
          <div className="flex items-center gap-4">
            <div
              onClick={togleSidebar}
              className={`icon_color rounded cursor-pointer flex items-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
            </div>
            <div className="px-2">
              <div className="p-2 __text text-center font-medium text-lg">{t("1_10075")}</div>
            </div>
          </div>
          <MyHeader user={user} />
        </header>
        <div className="p-6 bg_layout_content"> {children} </div>
      </section>
    </div>
  )
}
export default MyLayout
